body,
html {
  height: 100%;
  background-repeat: no-repeat;
  position: relative;
}

.container {
  margin-left: 0 !important;
}

#login-box {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 350px;
  margin: 0 auto;
  background-color: rgba(249, 191, 191, 0.323);
  border: 1px solid #c96;
  border-radius: 10px;
  backdrop-filter: blur(4px);
  min-height: 250px;
  padding: 20px;
  z-index: 9999;
}

#login-box .logo .logo-caption {
  text-align: center;
  margin-bottom: 0;
}

#login-box .logo .tweak {
  color: #000000;
}

#login-box .controls {
  padding-top: 30px;
}

#login-box .controls input {
  border-radius: 0;
  background: transparent;
}

#login-box .controls input:focus {
  box-shadow: none;
}

#login-box .controls input:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

#login-box .controls input:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

#login-box button.btn-custom {
  border-radius: 2px;
  margin-top: 8px;
  background: #000000;
  border-color: #302e2d;
  color: #fff;
}

#login-box button.btn-custom:hover {
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  background: #c96;
}

#particles-js {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
  position: fixed;
  top: 0;
  z-index: 1;
}

input::placeholder {
  color: #999 !important;
}

input:-ms-input-placeholder {
  color: #999 !important;
}

input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #999 !important;
}
