.delete-photo {
    top: -9px;
    right: -10px;
    color: #fff;
    background: red;
    font-weight: bold;
    text-align: center;
    width: 25px;
    display: inline-block;
    border-radius: 50%;
    cursor: pointer;

}
.cp{
    cursor: pointer;
}